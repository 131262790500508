.design-page-wrapper {
    .sidebar {
        height: calc(100vh - #{$navbar-height});
        border-right: 1px solid rgb(0 0 0 / 8%);
        position: relative;
        left: -10.5px;
        height: 100vh;

        &.no-line {
            border-right: none;
        }

        .accordion {
            width: $sidebar-width !important;
            height: calc(100vh - #{$navbar-height});
            overflow-y: scroll;

            .sidebar-item {
                button.sidebar-button {
                    font-size: 20px !important;
                    color: $font;

                    &:not(.collapsed) {
                        background-color: rgb(0 0 0 / 8%);
                    }
                }

                .sidebar-links {
                    li {
                        &::marker {
                            content: none;
                        }

                        a {
                            color: $font;
                            margin-bottom: 6px;

                            svg {
                                width: 16px;
                                position: absolute;
                                left: 11px;
                                fill: $green;
                            }
                        }

                        &.active {
                            a {
                                color: $green;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }

    }

    .main {
        padding-top: 65px;
        width: calc(100vw - #{$sidebar-width});
        height: calc(100vh - #{$navbar-height});
        overflow-y: scroll;

        @include media-breakpoint-down(md) {
            width: auto;
        }
    }

    .intro {
        div.parent-page-title {
            font-size: 18px;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
    }
}