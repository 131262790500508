.image-list-block {
    .image {
        border: 1px solid transparent;
        margin-bottom: 32px;

        img {
            height: 100%;
            object-fit: contain;
        }

        .download-container {
            width: 100%;
            padding: 12px;

            .caption {
                font-size: 16px;
            }

            a {
                cursor: pointer !important;
                pointer-events: auto;

                svg {
                    cursor: pointer !important;
                }
            }
        }

        &:hover {
            border: 1px solid $primary-blue;
            border-radius: 4px;
        }
    }
}