html {
    @include media-breakpoint-only(xs) { 
        font-size: clamp(9px, 2.08333vw, 10px);
    }
    @include media-breakpoint-only(sm) { 
        font-size: clamp(9px, 1.52439vw, 10px);
    }
    @include media-breakpoint-only(md) { 
        font-size: clamp(9px, 0.99vw, 10px);
    }
    @include media-breakpoint-up(lg) { 
        font-size: clamp(9px, 0.71685vw, 10px);
    }
    
    &.translated-ltr{
        header{
            top: 24px !important;

            &.show-menu{
                margin-top: -16px;
            }
        }
    }
}
$sidebar-width: 240px;
$navbar-height: 104px;
body {
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $white;
    font-family: 'futura-pt';
    min-height: 100vh;
    font-size: 2rem;
    overflow-x: hidden;
    width: 100%;

    &.menu-open{
        @include media-breakpoint-down(lg){
            position: fixed;
        }

        .header-container{
            @include media-breakpoint-up(lg){
                flex: 0 0 auto;
                width: 50%;
            }
        }

        .map-row{
            .mapboxgl-map{
                z-index: -2;
            }
        }
    }

    &.page-categorypage, &.page-stationindexpage, &.page-stationpage{
        header{
            .google-translate-container{
                right: 54%;
            }
        }
    }

    &.menu-open{
        header{
            .google-translate-container{
                right: 57%;
            }
        }
    }

    // &:not(.menu-open):not(.split-screen):not(.page-stationindexpage){
    //     padding-top: 104px;
    // }

    @include media-breakpoint-down(lg){
        &.search-open{
            position: fixed !important;
        }
    }

    section{
        background-color: $white;
        min-height: 50vh;
    }
    .main {
        padding-top: 65px;
        width: calc(100vw - #{$sidebar-width});
        height: calc(100vh - #{$navbar-height});
        overflow-y: scroll;

        @include media-breakpoint-down(md) {
            width: auto;
        }
    }
}

h1, h2, h3 {
    color: $black;
}

h1{
    font-size: 5.2rem;
    line-height: 5.4rem;
    
    @include media-breakpoint-down(md){
        font-size: 3.2rem;
        line-height: 3.6rem;
    }
}

h2{
    font-size: 3.2rem;
    line-height: 4rem;

    @include media-breakpoint-down(md){
        font-size: 2.8rem;
        line-height: 3.1rem;
    }
}

h3{
    font-size: 2.8rem;
    line-height: 3.6rem;
    font-weight: 600;
}

h4{
    font-size: 2.2rem;
    line-height: 2.8rem;
    font-weight: 600;
}

h5{
    font-size: 2rem;
    line-height: 2.8rem;
    font-weight: 600;
}

p, span{
    font-size: 2.2rem;
    line-height: 3.2rem;
}
p{
    margin-bottom: 2.2rem;
}


.intro {
    margin-bottom: 50px;

    @include media-breakpoint-down(md){
        margin-bottom: 30px;
    }

    p{
        line-height: 3rem;
        line-height: clamp(3rem, calc(2.7333333333333334rem + 0.34722222222222215vw), 3.4rem);
        font-size: 2rem;
        font-size: clamp(2rem, calc(1.7333333333333334rem + 0.34722222222222215vw), 2.4rem);
    }
}

a, .blue{
    color: $primary-blue;
    text-decoration: none;

    &:hover{
        color: $blue-hover;
    }
}

.btn-disabled{
    color: $btn-disabled;
}

.width-100-mobile{
    @include media-breakpoint-down(md){
        min-width: 100vw;
    }
}

.mobile-w-100{
    @include media-breakpoint-down(xl){
        min-width: 100%;
    }
}

.right-column{
    min-height: 97vh;
}

.bg-none{
    background-color: transparent;
}

.bg-cover{
    background-size: cover;
}

button{
    &:active, &:focus{
        outline: none !important;
        box-shadow: none !important;
    }
}

.scale-fix{
    @media (min-width: 1200px) and (max-width: 1300px){
        margin-left: 0px !important;
    }
}

.overflow-scroll{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
}

.dot{
    height: 10px;
    min-width: 10px;
    width: 10px;
    &.closed{
        background-color: $ahaettuurgangur!important;
    }

    &.open{
        background-color: $primary-green;
    }
}

.mb-n100{
    margin-bottom: -100px;
}

.md-break{
    @include media-breakpoint-down(md){
        flex-basis: 100%;
        height: 0;
    }
}
