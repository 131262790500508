header {
    nav.navbar {
        height: $navbar-height;
        border-bottom: 1px solid rgb(0 0 0 / 8%) !important;

        .navbar-brand {
            img {
                max-width: 126px;
            }
        }

        .navbar-outer-container {
            .navbar {

                ul,
                li {
                    white-space: nowrap;

                    a {
                        color: $font;

                        &:hover {
                            color: $green;
                        }
                    }

                    &.active {
                        a {
                            color: $green;
                            font-weight: 600;
                        }
                    }
                }

                .nav-item {
                    &:first-child {
                        margin-right: 20px;
                    }
                }

                .nav-link {
                    font-size: clamp(18px, 2vw, 20px);
                }
            }

            .user-info {
                font-size: clamp(16px, 2vw, 18px);
                white-space: nowrap;
                align-items: baseline;

                svg {
                    width: 19px;
                    margin-right: 10px;
                    fill: $green;
                }

                .divider {
                    padding: 0 8px;
                }

                .logout {
                    a {
                        color: $font;
                    }
                }
            }
        }
    }

    .navbar-menu {
        background-color: white;
        height: calc(100vh - #{$navbar-height});
        width: 100vw;
        position: absolute;
        top: $navbar-height;
        padding-top: 30px;

        .dropdown-menu {
            &.show {
                font-size: 20px;
                border: none;
            }
        }
        
        a {
            &.active {
                color: green !important;
            }

            &.navlink,
            &.dropdown-toggle {
                margin: 0 15px;
                font-weight: 600;

                &::after {
                    float: right;
                }
            }

            &.dropdown-item {
                padding: 5px 15px;
                font-size: clamp(18px, 2vw, 20px);
                &.active {
                    color: green !important;
                    background-color: transparent !important;
                }
            }
        }

        .user-info {
            font-size: 18px;
            margin: 40px 0 0 15px;

            svg {
                width: 17px;
                top: -2px;
            }

            .logout {
                a {

                    color: $font;
                }
            }
        }
    }
}

.hamburger {
    border: none;
}

@include media-breakpoint-down(md) {
    header {
        nav.navbar {
            height: 104px;

            .navbar-brand {
                img {
                    max-width: 110px;
                }
            }

            .navbar-outer-container {
                .navbar {
                    .nav-item {
                        &:first-child {
                            margin-right: 0px;
                        }
                    }
                }

                .user-info {
                    .logout {
                        display: flex;
                        justify-content: end;
                        margin-right: 10px;
                    }
                }
            }
        }

    }

}

@include media-breakpoint-down(sm) {
    header {
        nav.navbar {
            height: 104px;

            .navbar-brand {
                img {
                    max-width: 100px;
                }
            }
        }
    }
}