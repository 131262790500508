.design-system-page-header {
    .login-container {
        .inner {
            .login-header {
                margin-bottom: 35px;
                font-size: 24px;
                svg {
                    margin-right: 8px;
                    fill: $green;
                    width: 25px;
                }
            }

            .login-content {
                form {
                    input.login-btn {
                        font-size: 20px;
                        background-color: $primary-green;
                        color: $white;
                        border: none;
                        padding: 4px 22px;
                        border-radius: 7px;
                        margin-top: 25px;
                    }
                }
            }
        }
    }
}