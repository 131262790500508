
[data-zoom-backdrop] {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 900;
	background-color: rgba(255, 255, 255, 0.8);
	opacity: 0;
	transition: opacity 400ms ease;
	pointer-events: none;
}

[data-zoom-backdrop='active'] {
	opacity: 1;
	pointer-events: auto;
}

[data-zoom-image] {
	position: relative;
	z-index: 800;
	transform: none;
	transition: transform 400ms ease;
	cursor: zoom-in;
}

[data-zoom-image='active'] {
	z-index: 1000;
	cursor: zoom-out;
}